import type { FilterItemOptions } from 'egenie-utils';
import type { Store } from './index';

export function filterItems(context: Store): FilterItemOptions[] {
  return [
    {
      type: 'date',
      field: 'date',
      label: '日期',
      selectValue: '1',
      data: [
        {
          value: '1',
          label: '创建日期',
        },
      ],
    },
    {
      type: 'input',
      field: 'wmsRefundOrderNo',
      label: '退货单编码',
    },
    {
      type: 'input',
      field: 'skuNo',
      label: 'SKU编码',
    },
    {
      type: 'input',
      field: 'productNo',
      label: '商品编码',
    },
    {
      type: 'select',
      mode: 'multiple',
      field: 'ownerIds',
      label: '货主',
      isParamList: true,
    },
    {
      type: 'select',
      mode: 'multiple',
      field: 'vendorShopIds',
      label: '供应商',
      isParamList: true,
    },
    {
      type: 'input',
      field: 'wmsOtherStockOrderNo',
      label: '出库单编号',
    },
  ];
}
