import { ExportModal, ExportStore, Programme, ProgrammeComponent } from 'egenie-utils';
import React from 'react';
import { getAllVendor, getOwner } from '../../../utils';
import { filterItems } from './filterItems';
import { mainSubStructureModel } from './mainSubStructureModel';

export class Store {
  constructor() {
    getOwner()
      .then((ownerIds) => this.programme.filterItems.addDict({ ownerIds }));
    getAllVendor()
      .then((vendorShopIds) => this.programme.filterItems.addDict({ vendorShopIds }));
  }

  public mainSubStructureModel = mainSubStructureModel(this);

  public programme = new Programme({
    gridModel: this.mainSubStructureModel,
    filterItems: filterItems(this),
    moduleName: 'egenieCloudWmsPurchaseOrderReturnOrderIndex',
  });

  public exportStore: ExportStore = new ExportStore({ parent: null });
}

const store = new Store();

export default function() {
  return (
    <>
      <ProgrammeComponent store={store.programme}/>
      <ExportModal store={store.exportStore}/>
    </>
  );
}
