import { message, Modal } from 'antd';
import type { PaginationData } from 'egenie-utils';
import { MainSubStructureModel, request, TimeStampFormatter } from 'egenie-utils';
import React from 'react';
import { logSubGrid } from '../../../utils';
import { detailGrid } from './detailGrid';
import type { MainItem } from './types';
import type { Store } from './index';

export function mainSubStructureModel(context: Store) {
  return new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'wmsRefundOrderNo',
          name: '退货单号',
          width: 250,
        },
        {
          key: 'wmsOtherStockOrderNo',
          name: '出库单编号',
          width: 250,
        },
        {
          key: 'vendorShopName',
          name: '供应商',
          width: 200,
        },
        {
          key: 'ownerName',
          name: '货主',
          width: 200,
        },
        {
          key: 'refundCount',
          name: '总数量',
          width: 80,
        },
        {
          key: 'totalPrice',
          name: '总金额',
          width: 80,
        },
        {
          key: 'createTime',
          name: '创建时间',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.createTime}/>,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'wmsRefundOrderId',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 200,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/purchaseOrder/returnOrder/index/main',
    },
    pageId: '60103',
    buttons: [
      {
        text: '导出',
        handleClick: () => {
          const ids = Array.from(context.programme.gridModel.gridModel.selectedIds).join(',');
          const fileName = '退货单';
          const exportType = 'cloud_refund_order';
          const queryParam = context.programme.filterItems.params;
          const queryParamShow = context.programme.filterItems.translateParams.join(' ');

          if (ids) {
            context.exportStore.onShow(fileName, exportType, ids, queryParam, queryParamShow);
          } else {
            Modal.confirm({
              title: '提示',
              content: '未选择数据将导出全部数据?',
              onOk: () => {
                context.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow);
              },
            });
          }
        },
      },
      {
        permissionId: '4090',
        text: '推送退款账单',
        handleClick: () => {
          const wmsRefundOrderIds = Array.from(context.programme.gridModel.gridModel.selectedIds);
          if (wmsRefundOrderIds.length === 0) {
            const error = '请至少选择数据一条';
            message.warn({
              key: error,
              content: error,
            });
            return;
          }

          Modal.confirm({
            content: '确定推送退款账单吗?',
            onOk: async() => {
              await request({
                url: '/api/cloud/wms/rest/refund/push/boss',
                method: 'post',
                data: { wmsRefundOrderIds },
              });
              message.success('推送退款账单成功');
            },
          });
        },
      },
    ],

    // 主表查询api
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        return request<PaginationData<MainItem>>({
          url: '/api/cloud/wms/rest/refund/order/query',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
        });
      },
    },
    subTables: {
      activeTab: 'detail',
      tabsFlag: {
        inited: { detail: true },
        searched: {},
      },
      list: [
        // @ts-ignore
        detailGrid(),
        logSubGrid(270000, {}),
      ],
    },
  });
}
