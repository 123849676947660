import type { PaginationData } from 'egenie-utils';
import { request } from 'egenie-utils';
import React from 'react';
import type { SubItem } from './types';

export function detailGrid() {
  return {
    filterItems: [
      {
        field: 'skuNo',
        label: 'sku编码',
        type: 'input',
      },
    ],
    allFilterItemsInOneGroup: false,
    tab: {
      name: '退货明细',
      value: 'detail',
    },
    grid: {
      columns: [
        {
          key: 'productNo',
          name: '商品编码',
          width: 200,
        },
        {
          key: 'skuNo',
          name: 'SKU编码',
          width: 200,
        },
        {
          key: 'color',
          name: '颜色',
          width: 100,
        },
        {
          key: 'size',
          name: '尺码',
          width: 100,
        },
        {
          key: 'refundCount',
          name: '数量',
          width: 100,
        },
        {
          key: 'costPrice',
          name: '成本价',
          width: 100,
        },
        {
          key: 'totalPrice',
          name: '总价',
          width: 100,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'wmsRefundOrderDetailId',
      sortByLocal: false,
      showCheckBox: false,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/purchaseOrder/returnOrder/index/detail',
    },
    api: {
      onQuery: ({
        data,
        pid,
      }) => {
        const {
          sidx,
          sord,
          page,
          pageSize,
          cond,
        } = data;
        return request<PaginationData<SubItem[]>>({
          url: '/api/cloud/wms/rest/refund/order/detail/query',
          method: 'POST',
          data: {
            wmsRefundOrderId: pid,
            sidx,
            sord,
            page,
            pageSize,
            ...cond,
          },
        });
      },
    },
  };
}
